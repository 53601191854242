import mapValues from 'lodash/fp/mapValues';

export const MOBILE_TABLET_LAYOUT = {
  DEFAULT: 'Default',
  SQUARE: 'Square',
  WIDE: 'Wide',
};

const gridSpanMap = {
  2: {
    default: 12,
    kilo: 12,
    mega: 6,
  },
  3: {
    default: 12,
    kilo: 4,
    mega: 4,
  },
  4: {
    default: 6,
    kilo: 6,
    mega: 3,
  },
};

export const aspectRatioMap = {
  2: {
    w: 500,
    h: 300,
  },
  3: {
    w: 500,
    h: 375,
  },
  4: {
    w: 500,
    h: 500,
  },
};

export function changeSizes(defaultSize, kiloSize) {
  return (value) => ({
    ...value,
    default: defaultSize,
    kilo: kiloSize || value.kilo,
  });
}

export function getGridSpanMap(type) {
  switch (type) {
    case MOBILE_TABLET_LAYOUT.SQUARE:
      return mapValues(changeSizes(6, 6), gridSpanMap);
    case MOBILE_TABLET_LAYOUT.WIDE:
      return mapValues(changeSizes(12), gridSpanMap);
    default:
      return gridSpanMap;
  }
}
