import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import isEmpty from 'lodash/fp/isEmpty';

import * as GalleryService from './GalleryService';

import { imagePropType } from '~/shared/util/shared-prop-types';
import Link from '~/shared/components/Link';
import Image from '~/shared/components/Image';
import dataSelector from '~/shared/util/data-selector';
import { mapVariations } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const DATA_SELECTOR = 'gallery';

const figureBaseStyles = ({ theme }) => css`
  position: relative;
  width: 100%;
  ${theme.mq.untilKilo} {
    margin-bottom: ${theme.grid.untilKilo.gutter};
  }

  ${theme.mq.kilo} {
    margin-bottom: ${theme.grid.kilo.gutter};
  }

  ${theme.mq.mega} {
    margin-bottom: 0;
  }
`;

const Figure = styled('figure')(figureBaseStyles);

const galleryImageBaseStyles = ({ theme }) => css`
  display: block;
  position: relative;
  border-radius: ${theme.borderRadius.byte};
  overflow: hidden;
  height: auto;
  width: 100%;
  transition: filter 0.2s ease-in-out;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
    background: radial-gradient(transparent, var(--cui-bg-strong));
    content: '';
    opacity: 0;
  }

  img {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
    will-change: transform;
    backface-visibility: hidden;
  }

  a:hover &,
  a:focus & {
    &::after {
      opacity: 0.1;
    }

    img {
      transform: scale(1.04);
    }
  }
`;

const GalleryImage = styled(Image)(galleryImageBaseStyles);

const figureCaptionBaseStyles = ({ theme }) => css`
  font-size: ${theme.typography.body.two.fontSize};
  line-height: ${theme.typography.body.two.lineHeight};
  background-color: rgba(255, 255, 255, 0.85);
  color: var(--cui-fg-normal);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: ${theme.spacings.byte} ${theme.spacings.kilo};
  width: 100%;
  transition: background-color 0.3s cubic-bezier(0, 0, 0.2, 1);

  a:hover &,
  a:focus & {
    background-color: var(--cui-bg-normal);
  }

  ${theme.mq.kilo} {
    border-radius: ${theme.borderRadius.byte};
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    margin: ${theme.spacings.mega};
    width: auto;
  }
`;

const FigureCaption = styled('figcaption')(figureCaptionBaseStyles);

const rowStyles = ({ theme }) => css`
  ${theme.mq.mega} {
    display: flex;
    justify-content: center;
  }
`;

const StyledRow = styled(Row)(rowStyles);

/**
 * Image Gallery to display between two and four images
 */
const Gallery = ({
  galleryImages: originalGalleryImages = [],
  mobileTabletLayout = GalleryService.MOBILE_TABLET_LAYOUT.DEFAULT,
}) => {
  const { experiments } = useOptimizelyData();
  const galleryImages = mapVariations(originalGalleryImages, experiments);

  if (isEmpty(galleryImages)) {
    return null;
  }

  const gallerySize =
    mobileTabletLayout === GalleryService.MOBILE_TABLET_LAYOUT.SQUARE
      ? 4
      : galleryImages.length;

  const calculatedGridSpanMap =
    GalleryService.getGridSpanMap(mobileTabletLayout);

  const renderImage = ({ caption, ...imageProps }) => (
    <Fragment>
      <GalleryImage {...imageProps} />
      {caption && <FigureCaption>{caption}</FigureCaption>}
    </Fragment>
  );

  return (
    <Grid
      data-selector={dataSelector('section', DATA_SELECTOR)}
      data-elbcontext="component:gallery"
    >
      <StyledRow>
        {galleryImages.map(
          ({ image, caption, link, contentType, name, id, altText }, i) => {
            if (!image) {
              return null;
            }

            const galleryImageSize = GalleryService.aspectRatioMap[gallerySize];

            const imageProps = {
              src: image.url,
              alt: image.alt || altText,
              width: galleryImageSize.w,
              height: galleryImageSize.h,
              contentful: {
                fit: 'fill',
                ...galleryImageSize,
              },
            };

            return (
              <Col
                span={calculatedGridSpanMap[gallerySize]}
                key={i}
                data-selector={dataSelector('gallery_item', DATA_SELECTOR)}
              >
                <Figure>
                  {link ? (
                    <Link
                      href={link}
                      trackingContentEntry={{
                        contentType,
                        contentEntryName: name,
                        contentEntryId: id,
                      }}
                    >
                      <a
                        aria-label="Gallery image link"
                        data-selector={dataSelector(
                          'gallery_item_link',
                          DATA_SELECTOR,
                        )}
                      >
                        {renderImage({ ...imageProps, caption })}
                      </a>
                    </Link>
                  ) : (
                    renderImage({ ...imageProps, caption })
                  )}
                </Figure>
              </Col>
            );
          },
        )}
      </StyledRow>
    </Grid>
  );
};

Gallery.propTypes = {
  /**
   * Array of gallery image objects
   */
  galleryImages: PropTypes.arrayOf(
    PropTypes.shape({
      image: imagePropType,
      caption: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  mobileTabletLayout: PropTypes.oneOf([
    GalleryService.MOBILE_TABLET_LAYOUT.SQUARE,
    GalleryService.MOBILE_TABLET_LAYOUT.WIDE,
    GalleryService.MOBILE_TABLET_LAYOUT.DEFAULT,
  ]),
};

export default Gallery;
